import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { Form, Col, Row } from 'reactstrap';
import { isEmpty, camelCase } from 'lodash';
import diff from 'd2l-json-patch-gen';
import Epic from '../../../services/Epic';
import store from '../../../store';
import { setEConsults, setIsSideDrawerOpen, setIsUpdatingEConsult } from '../../../state';
import ErrorCountAlert from '../../common/error-count-alert/ErrorCountAlert';
import SeparatorTitle from '../../common/separator-title/SeparatorTitle';
import EConsults from '../../../api-calls/EConsults';
import ResponseAttachments from './response-attachments/ResponseAttachments';
import RecommendationEditor from './recommendation-editor/RecommendationEditor';
import ResponseApropriateness from './response-appropriateness/ResponseApropriateness';
import ResponseDisclaimer from './response-disclaimer/ResponseDisclaimer';
import SpecialistReviewModal from '../specialist-review/modal/Modal';
import SubmitButton from './submit-button/SubmitButton';
import ConfirmationModal from '../../confirmation-modal/ConfirmationModal';
import SpecialistTimeSpent from '../specialist-time-spent/SpecialistTimeSpent';
import axios from '../../../services/axios';
import EConsult from '../../../services/EConsult';

const ResponseBody = ({ eConsult, desktopVersion }) => {
  let loopTimeout = null;
  let heartbeatTimeout = null;
  const history = useHistory();
  /**
   * Once the eConsult has been dispositioned the specialist will only able to provide comments
   * this method returns that verification
   * @return {boolean} is the provider waiting for an specialist comment
   */
  const isWaitingForSpecialistComment = () =>
    eConsult.status === 'pending_specialist_feedback';

  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const isAlreadySubmitted = useRef(false);
  const [modalHeaderContent, setModalHeaderContent] = useState('');
  const [modalBodyContent, setModalBodyContent] = useState('');
  const [modalButtons, setModalButtons] = useState({ ok: { color: '', text: '', onClick: () => {} }, cancel: null });
  const isTimeSpentEnabled = eConsult.panel.enable_time_spent;

  const [disclaimerText, setDisclaimerText] = useState(null);
  const [disclaimerID, setDisclaimerID] = useState(null);

  const fetchDisclaimer = () => (
    axios.get('disclaimer').then(({data})=>{
      setDisclaimerText(data.text);
      setDisclaimerID(data.id);
    })
  );
  useEffect(() => {
    if (!disclaimerText) {
      fetchDisclaimer();
    }
  },[])

  const isCorrectionalFacility = EConsult.isCorrectionalFacility(eConsult);

  /**
   * Original form state instantiated as a ref for asynchronous process (timeout)
   * @type {React.MutableRefObject<{
   * specialist_form_version: *,
   * recommendation: (String|string),
   * appropriateness: (String|string),
   * disclaimer: (Boolean|boolean)}>
   * }
   */
  const originalForm = useRef({
    ...(isWaitingForSpecialistComment() ? {
      comment: '',
      appropriateness: eConsult.appropriateness || null,
      recommended_specialty: eConsult.recommended_specialty || null,
      visit_urgency: eConsult.visit_urgency || null,
      disclaimer: false,
    } : {
      recommendation: eConsult.recommendation || '',
      appropriateness: eConsult.appropriateness || null,
      disclaimer: eConsult.disclaimer || false,
      specialist_form_version: eConsult.specialist_form_version,
      recommended_specialty: eConsult.recommended_specialty || null,
      disposition_attachments: eConsult.disposition_attachments || [],
      visit_urgency: eConsult.visit_urgency || null,
    }),
    ...(isTimeSpentEnabled ? { specialist_time_spent: eConsult.specialist_time_spent || '' } : {}),
  });

  // Define the structure of the default values in case the specialist must provide a
  // disposition or not
  const defaultValues = { ...originalForm.current };
  if (!isWaitingForSpecialistComment()) {
    defaultValues.specialist_form_version = '3';
  }
  /**
   * React hook form
   */
  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    register,
    errors,
    getValues,
  } = methods;

  /**
   * Once the changes are saved the original version of the form is reset
   */
  const resetForm = (data) => {
    originalForm.current = {
      recommendation: data.recommendation || '',
      appropriateness: data.appropriateness || null,
      disclaimer: data.disclaimer || false,
      specialist_form_version: data.specialist_form_version,
      recommended_specialty: data.recommended_specialty || null,
      disposition_attachments: data.disposition_attachments || [],
      visit_urgency: data.visit_urgency || null,
      ...(isTimeSpentEnabled ? { specialist_time_spent: data.specialist_time_spent || '' } : {}),
    };
  };

  /**
   * Calculates the difference between the original state vs the new changes and performs a
   * json-patch operation in order to upload the changes into the server
   * as true
   * @returns {*|[]} this is an array with json-patch operations in case there are differences
   */
  const calculateDifference = () => diff({ ...originalForm.current }, { ...getValues() });

  /**
   * Resets the econsults queue and redirects the user to the main page
   */
  const returnToQueue = () => {
    store.dispatch(setEConsults([]));
    store.dispatch(setIsSideDrawerOpen(desktopVersion));
    history.push('/');
  };

  const openReviewModal = () => setIsReviewModalOpen(!isReviewModalOpen);

  const openConfirmationModal = () => setIsConfirmationModalOpen(!isConfirmationModalOpen);

  /**
   * Calls the updatEconsult saga in order to patch the econsult
   * @param difference what is the difference to patch
   * @param successCallback executed if eConsult was patched
   * @param failCallback executed if eConsult was not patched
   */
  const updateEConsult = (difference, successCallback = () => {}, failCallback = () => {}) => {
    store.dispatch({
      type: 'econsultsSaga/UPDATE_ECONSULT',
      econsultId: eConsult.id,
      difference,
      successCallback,
      failCallback,
    });
  };

  /**
   * Once the eConsult has been dispositioned the specialist must be able to post a
   * follow-up comment
   */
  const postFollowUpComment = () => {
    const { comment } = getValues();
    // Set the flag is updating econsult, this will disable the submit button and show a spinner
    store.dispatch(setIsUpdatingEConsult(true));
    EConsults().postComment(eConsult.id, { type: 'follow_up', comment }).then(() => {
      store.dispatch(setIsUpdatingEConsult(false));
      updateEConsult([{ op: 'replace', path: '/status', value: 'follow_up' }], returnToQueue);
    });
  };

  /**
   * Calls the server in order to update the info
   * @param transition what should be the transition to apply, save by default
   */
  const submitData = (transition = 'save') => {
    // This must be reversed since the vendor sends attachment addition operations in wrong order
    const difference = calculateDifference().reverse();
    if ((difference.length > 0 && transition === 'save') || transition === 'submit') {
      difference.push({ op: 'replace', path: '/status', value: transition });
    }
    if (transition === 'submit') {
      difference.push({ op: 'replace', path: '/disclaimer_id', value: disclaimerID });
    }

    const epicCSN = Epic.getCSN();
    if (epicCSN && transition === 'submit') {
      difference.push({ op: 'replace', path: '/csn', value: epicCSN });
      Epic.forgetCSN();
    }

    if (difference.length > 0) {
      updateEConsult(difference, (response) => {
        if (transition === 'submit') {
          isAlreadySubmitted.current = true;
          openReviewModal();
        }
        resetForm(response.data);
      }, (error) => {
        if (!isWaitingForSpecialistComment()) {
          clearTimeout(loopTimeout);
        }
        clearTimeout(heartbeatTimeout);
        let message;
        if (error.response.status === 403) {
          message = 'This eConsult is no longer assigned to you.';
        } else if (error.response.data.alreadyDispositioned) {
          message = `A response has already been received for eConsult C${eConsult.id}`;
        } else {
          message = 'Internal server error';
        }
        /* This will feed the notification modal */
        setModalHeaderContent('Error found during save process');
        setModalBodyContent((<p>{message}</p>));
        setModalButtons({ ok: { text: 'Return to Queue', onClick: returnToQueue, color: 'primary' }, cancel: null });
        openConfirmationModal();
      });
    }
  };

  /**
   * Submit the eConsult Response in two scenarios auto-save feature if the
   * disposition is different from face to face and the visit urgency is different from
   * immediate
   */
  const eConsultResponseSubmit = () => {
    if (getValues().appropriateness === 'face_to_face' && getValues().visit_urgency === 'emergent') {
      /* This will feed the notification modal */
      setModalHeaderContent('Confirm this is a medical emergency');
      setModalBodyContent((
        <React.Fragment>
          <p>AristaMD will automatically email/send a text message to the panel manager,
            requesting provider and emergency contact(s) at the clinic to immediately follow up
            with this patient
          </p>
          <p>Do you wish to proceed?</p>
        </React.Fragment>));
      setModalButtons({
        ok: {
          text: 'Proceed',
          onClick: () => {
            submitData('submit');
          },
          color: 'primary',
        },
        cancel: {
          text: 'Cancel',
          onClick: () => {
            openConfirmationModal();
          },
          color: 'secondary',
        },
      });
      openConfirmationModal();
    } else {
      submitData('submit');
    }
  };

  /**
   * Sets a time out of 3 seconds in order to check differences and upload them to the server
   * @returns {number} time out id
   */
  const setSubmitTimeout = () => setTimeout(() => {
    if (!isAlreadySubmitted.current) {
      submitData();
      loopTimeout = setSubmitTimeout();
    }
  }, 3000);

  /**
   * Sets a time out of 30 seconds in order to send an econsult heartbeat
   * @returns {number} time out id
   */
  const setHeartbeatTimeout = () => setTimeout(() => {
    EConsults().heartbeat(eConsult.id);
    heartbeatTimeout = setHeartbeatTimeout();
  }, 30000);

  useEffect(() => {
    // Do not trigger the auto save if the specialist has to provide a feedback comment
    if (!isWaitingForSpecialistComment()) {
      loopTimeout = setSubmitTimeout();
    }
    heartbeatTimeout = setHeartbeatTimeout();
    return () => {
      // Do not clear the auto save timeout if the specialist has to provide a feedback comment
      if (!isWaitingForSpecialistComment()) {
        clearTimeout(loopTimeout);
      }
      clearTimeout(heartbeatTimeout);
    };
  }, [eConsult]);

  if (!isEmpty(errors)) {
    // eslint-disable-next-line array-callback-return
    Object.keys(errors).map((error) => {
      switch (error) {
        case 'disclaimer':
          break;
        case 'appropriateness':
          break;
        case 'recommended_specialty':
          break;
      }
    });
  }

  return (
    <FormProvider {...methods}>
      <Row className="response-body-container">
        <Col>
          <SeparatorTitle title="Your Response" />
          { isCorrectionalFacility
            && (
              <div className="corrections-text">
                <strong>CORRECTIONS PATIENT: </strong>
                Limited imaging, diagnostics and dietary flexibility. Prioritize imaging and
                diagnostics based on risks associated with off-site care.
                { desktopVersion
                  && (
                    <>
                      <br />
                      <br />
                      <strong>Important information to include:</strong>
                      <ul>
                        <li>Actions for referring provider & patient</li>
                        <li>Interim care planning advice</li>
                        <li>Followup instructions</li>
                      </ul>
                    </>
                  )}
              </div>
            )}
          <ErrorCountAlert errors={errors} />
          <Form
            className="specialist-response-form"
            onSubmit={handleSubmit(() => (isWaitingForSpecialistComment() ?
              postFollowUpComment() : eConsultResponseSubmit()))}
            data-auto="response-body-form"
          >
            <RecommendationEditor
              eConsult={eConsult}
              name={isWaitingForSpecialistComment() ? 'comment' : 'recommendation'}
              attachmentHandler={() => setIsAttachmentModalOpen(!isAttachmentModalOpen)}
              label={isWaitingForSpecialistComment() ? 'Add your comment' : 'Add your care plan advice'}
              isAttachmentRequired={!isWaitingForSpecialistComment()}
              desktopVersion={desktopVersion}
            />
            { !isWaitingForSpecialistComment() ? <ResponseAttachments
              eConsult={eConsult}
              isAttachmentModalOpen={isAttachmentModalOpen}
              handleClose={() => setIsAttachmentModalOpen(!isAttachmentModalOpen)}
              desktopVersion={desktopVersion}
            /> : null }
            <ResponseApropriateness
              eConsult={eConsult}
              isWaitingForSpecialistComment={isWaitingForSpecialistComment}
              desktopVersion={desktopVersion}
            />
            { isTimeSpentEnabled ? <SpecialistTimeSpent timeSpentOptions={eConsult.panel.time_spent_options ?? []} /> : false }
            {
              disclaimerText &&
               <ResponseDisclaimer desktopVersion={desktopVersion} disclaimerText={disclaimerText}/>
            }
            <input
              name="specialist_form_version"
              type="hidden"
              ref={register}
            />
            <SubmitButton />
          </Form>
          <SpecialistReviewModal
            eConsult={eConsult}
            toggle={openReviewModal}
            isOpen={isReviewModalOpen}
            onClosed={returnToQueue}
            desktopVersion={desktopVersion}
          />
          <ConfirmationModal
            toggle={openConfirmationModal}
            isOpen={isConfirmationModalOpen}
            desktopVersion={desktopVersion}
            modalHeaderContent={modalHeaderContent}
            className="confirmation-modal-header"
            modalBodyContent={modalBodyContent}
            modalButtons={modalButtons}
          />
        </Col>
      </Row>
    </FormProvider>
  );
};

ResponseBody.propTypes = {
  eConsult: PropTypes.shape({
    id: PropTypes.number,
    appropriateness: PropTypes.string,
    disclaimer: PropTypes.bool,
    recommendation: PropTypes.string,
    specialist_form_version: PropTypes.string,
    status: PropTypes.string,
    specialty_name: PropTypes.string,
    patient_age_in_days: PropTypes.number,
    organization_id: PropTypes.number,
    recommended_specialty: PropTypes.string,
    full_details: PropTypes.shape({
      disposition_due_date: PropTypes.string,
    }),
    disposition_attachments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      lastModified: PropTypes.number,
    })),
  }).isRequired,
  desktopVersion: PropTypes.bool,
};

ResponseBody.defaultProps = {
  desktopVersion: false,
};

export default ResponseBody;
